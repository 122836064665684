import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Container, Row } from "react-bootstrap";
import DSNavBar, { SandCont } from "./header-common";
import DuneLogo from "./brand-logo";

const PageHeading = styled.div`
  padding-top: 4%;
  padding-bottom: 4%;
  text-align: center;

  h1 {
    margin: 0;
    color: white;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
    font-size: 2.5em;
  }
  @media only screen and (max-device-width: 640px) {
    padding-top: 0%;
    h1 {
      font-size: 2em;
    }
  }
`;

const Header = ({ showLogo, title }) => (
  <header>
    <SandCont>
      <DSNavBar />

      {showLogo && (
        <Container>
          <Row>
            <DuneLogo />
          </Row>
        </Container>
      )}
      {title && (
        <PageHeading>
          <h1>{title.toUpperCase()}</h1>
        </PageHeading>
      )}
    </SandCont>
  </header>
);

Header.propTypes = {
  title: PropTypes.string,
  showLogo: PropTypes.bool,
};

Header.defaultProps = {
  title: "",
  showLogo: false,
};

export default Header;
