import React from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { Navbar, Nav } from "react-bootstrap";
import GlobalStyle from "./global";

import neon from "../images/neon-yellow-left.jpg";
import duneIcon from "../images/frolic-icon-small.png";


import "../landing-page.css";

export const SandCont = styled.div`
  background: url(${neon}) no-repeat center center;
  background-size: cover;
`;



const scrollTo = (toPage, href) => {
  navigate(toPage);
};

const DSNavBar = props => (
  <>
    <Navbar collapseOnSelect expand="lg" bg="semi" variant="dark">
      <Navbar.Brand>
        <Link to="/">
          <img
            src={duneIcon}
            alt="DuneSea logo"
            width="36"
            height="36"
            className="reset-margin"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
         {/*  <Nav.Link href="#" onSelect={() => navigate("/blog")}>
            BLOG
          </Nav.Link> */}
          <Nav.Link
            href="#"
            onSelect={() => navigate("/press")}
          >
            PRESS KIT
          </Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="#" onSelect={() => scrollTo("/", "#contact")}>
            CONTACT
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <GlobalStyle />
    </Navbar>
  </>
);

export default DSNavBar;
