import React from "react";
import styled from "styled-components";

// import dunelogo from "../images/dune-logo.png";
import froliclogo from "../images/FrolicLogo-200.png"; 

const LogoWrap = styled.div`
  text-align: center;
  vertical-align: middle;
  opacity: 0.78;
`;

/*
const DuneLogo = () => (
  <LogoWrap>
    <img src={dunelogo} alt="Logo" />
  </LogoWrap>
); */

const FrolicLogo = () => (
  <LogoWrap>
    <img src={froliclogo} alt="FLogo" />
  </LogoWrap>
);

export default FrolicLogo;
